$TIMELINE_PADDING_Y: 20px;
$ELEMENT_MARGIN_Y: 12px;
$ELEMENT_MARGIN_LEFT: 44px;
$BADGE_DIMENSIONS: 30px;
$LINE_WIDTH: 2px;

.timeline-component {
  padding: $TIMELINE_PADDING_Y 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &__line {
    position: absolute;
    top: 0;
    left: $BADGE_DIMENSIONS / 2 - 1px;

    width: $LINE_WIDTH;
    height: $TIMELINE_PADDING_Y + $ELEMENT_MARGIN_Y;
    background: var(--secondary--light);
  }

  &__element {
    position: relative;
    margin: $ELEMENT_MARGIN_Y 0 $ELEMENT_MARGIN_Y $ELEMENT_MARGIN_LEFT;

    & > div:not(:last-child) {
      margin-bottom: 4px;
    }

    &__badge {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -5px;
      left: -$ELEMENT_MARGIN_LEFT;
      width: $BADGE_DIMENSIONS;
      height: $BADGE_DIMENSIONS;
      background: #CCC;
      border-radius: 50%;
      font-size: 14px;
      color: #fff
    }

    &__line {
      position: absolute;
      top: 5px;
      left: -$ELEMENT_MARGIN_LEFT + $BADGE_DIMENSIONS / 2 - 1px;
      width: $LINE_WIDTH;
      height: calc(100% + 20px);
    }

    &__title, &__subtitle {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 700;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 14px;
      color: #555;
    }

    &__date {
      font-size: 14px;
      font-weight: 700;
    }
  }
}
