.card-component {
  padding: var(--default-margin);
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--default-border-radius);

  &--fit {
    height: fit-content;
  }

  &--fill {
    height: 100%;
  }
}
