html {
  box-sizing: border-box;
  font-size: 10px;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  height: 100%;
}

:root {
  --header-height-desktop: 56px;
  --header-height-mobile: 56px;

  --transition-fast: 200ms;

  --height-small: 24px;
  --height-medium: 32px;
  --height-large: 40px;

  --default-border-radius: 2px;

  --default-margin: 1rem;
}
