@import '../styles/mixins';

.app {
  height: 100%;

  &.light {
    --app-background-primary: #fff;
    --app-background-secondary: #ccc;
    --app-font-color: #2c3e50;

    --border-color: #eaecef;
  }
  &.dark {
    --app-background-primary: #1e2125;
    --app-background-secondary: #121212;
    --app-font-color: #eee;

    --border-color: #121212;
  }

  color: var(--app-font-color);
}

.app-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: var(--app-background-primary);
  border-bottom: 1px solid var(--border-color);
  height: var(--header-height-mobile);
  padding: 0 2.4rem;
  font-size: 2rem;

  &__burger {

  }

  &__logo {
    font-weight: 600;
  }

  &__right {
    display: flex;

    & > *:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__actions {
    display: none;
  }

  @include for-tablet-up {
    height: var(--header-height-desktop);

    &__burger {
      & > * {
        display: none;
      }
    }

    &__actions {
      display: flex;
      font-size: 1.4rem;
      & > *:not(:last-child) {
        margin-right: 1.6rem;
      }
    }
  }
}

.app-main {
  display: flex;
  flex-direction: column;
  padding-top: var(--header-height-mobile);
  background: var(--app-background-primary);
  overflow-y: auto;
  min-height: 100vh;
  width: 100%;
}

.app-content {
  display: flex;
  //padding: var(--default-margin);
  width: 100%;
  flex-grow: 1;

  & > div:not(:last-child) {
    margin-right: 1.6rem;
  }

  @include for-desktop-l-up {
    margin-left: auto;
    margin-right: auto;
    width: 1440px;
  }
}
