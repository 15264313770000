.view {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.view__row {
  display: flex;
  width: 100%;
  //padding: 0 var(--default-margin);
}

.view__element {
  width: 100%;
  margin: var(--default-margin);
}

